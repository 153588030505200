import React, { useState, useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"
import * as R from "ramda"

import urlForContentType from "../data/url-for-content-type.json"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import Hero from "../components/page/Hero"

import ArticleLift from "../components/page/ArticleLift"

import SearchContext from "../contexts/SearchContext"

import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/SEO"

import constants from "../components/theme/constants"

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: "1.5em",
  },
  heading: {
    textTransform: "uppercase",
  },
  container: {
    marginTop: "4rem",
  },
  allServices: {
    marginTop: "2em",
    marginBottom: "4em",
  },
  viewMoreButton: {
    textAlign: "center",
  },
}))

const PalvelutPage = ({ data, pageContext, location }) => {
  const allServices = R.path(
    ["allContentfulServiceOffering", "edges"],
    data
  ).map(node => node.node)

  const [services, setServices] = useState(allServices)

  const availableSorting = ["Osuvimmat", "Uusimmat ensin", "Vanhimmat ensin"]
  const [sortState, setSortState] = useState("Osuvimmat")

  // This is the "View more" functionality
  // It is basedaround pages as that may be use
  const servicesPerPage = 9
  const [shownServices, setShownServices] = useState(servicesPerPage)

  const handleViewMoreServices = () => {
    if (shownServices < services.length) {
      setShownServices(shownServices + servicesPerPage)
    } else {
      setShownServices(services.length)
    }
  }

  const classes = useStyles()

  const searchContext = useContext(SearchContext)
  searchContext.setHeroLoaded(true);

  return (
    <Layout>
      <SEO title="Toimitilahaku | Palvelut" />
      <Hero
        headline={R.path(["page", "palvelutPageHeading"], data)}
        backgroundImageFluid={R.path(
          ["page", "palvelutPageHeadingImage", "gatsbyImageData"],
          data
        )}
        compact
      ></Hero>

      <Container className={classes.container}>
        {services.length && (
          <Grid container className={classes.allServices} spacing={5}>
            {// Slice is used to load more pages of results as requested by user
            R.slice(
              0,
              shownServices,
              services.map(service => {
                return (
                  <Grid item xs={12} md={4} key={service.id}>
                    <ArticleLift
                      image={service.headingImage}
                      heading={
                        service.heading ? service.heading : service.title
                      }
                      text={R.path(["leadText", "leadText"], service)}
                      urlPath={
                        urlForContentType[
                          R.path(["sys", "contentType", "sys", "id"], service)
                        ]
                      }
                      slug={service.slug}
                    />
                  </Grid>
                )
              })
            )}

            {services.length > shownServices && (
              // This condition has the potential to display an extra "view more" button when on the last page of results
              // The impact and probability are both minimal, so leaving the issue instead of complicating the logic further
              <Grid item xs={12} className={classes.viewMoreButton}>
                <Button
                  variant="outlined"
                  color="primary"
                  disableRipple={true}
                  disableFocusRipple={true}
                  onClick={handleViewMoreServices}
                >
                  Katso lisää
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export default PalvelutPage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query PalvelutPageQuery {
    allContentfulServiceOffering(filter: { slug: { ne: "_blank" } }) {
      edges {
        node {
          id
          slug
          title
          heading
          leadText {
            leadText
          }
          headingImage {
            gatsbyImageData(
              formats: [AUTO, WEBP],
              layout: CONSTRAINED, 
              width: 550, 
              quality: 70,
            )
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
    page: contentfulSiteGlobal(activeVersion: { eq: "Kyllä" }) {
      palvelutPageHeading
      palvelutPageHeadingImage {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 1200, 
          quality: 70,
        )
      }
    }
  }
`
